"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoteAction = exports.CourseReportType = exports.ReviewReportType = exports.IssueReportType = exports.ReportCategory = exports.ErrorCode = void 0;
var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["AUTHORIZATION_INVALID_TOKEN"] = 0] = "AUTHORIZATION_INVALID_TOKEN";
    ErrorCode[ErrorCode["AUTHORIZATION_REFRESH_TOKEN"] = 1] = "AUTHORIZATION_REFRESH_TOKEN";
    ErrorCode[ErrorCode["INVALID_COURSE_ID"] = 2] = "INVALID_COURSE_ID";
    ErrorCode[ErrorCode["CREATE_USER_INVALID_USERNAME"] = 3] = "CREATE_USER_INVALID_USERNAME";
    ErrorCode[ErrorCode["CREATE_USER_INVALID_EMAIL"] = 4] = "CREATE_USER_INVALID_EMAIL";
    ErrorCode[ErrorCode["CREATE_USER_USERNAME_EXISTS"] = 5] = "CREATE_USER_USERNAME_EXISTS";
    ErrorCode[ErrorCode["CREATE_USER_EMAIL_EXISTS"] = 6] = "CREATE_USER_EMAIL_EXISTS";
    ErrorCode[ErrorCode["VERIFICATION_FAILED"] = 7] = "VERIFICATION_FAILED";
    ErrorCode[ErrorCode["VERIFICATION_ALREADY_VERIFIED"] = 8] = "VERIFICATION_ALREADY_VERIFIED";
    ErrorCode[ErrorCode["VERIFICATION_USER_DNE"] = 9] = "VERIFICATION_USER_DNE";
    ErrorCode[ErrorCode["VERIFICATION_EXPIRED"] = 10] = "VERIFICATION_EXPIRED";
    ErrorCode[ErrorCode["LOGIN_FAILED"] = 11] = "LOGIN_FAILED";
    ErrorCode[ErrorCode["LOGIN_USER_DNE"] = 12] = "LOGIN_USER_DNE";
    ErrorCode[ErrorCode["LOGIN_NOT_VERIFIED"] = 13] = "LOGIN_NOT_VERIFIED";
    ErrorCode[ErrorCode["GET_PASSWORD_USER_DNE"] = 14] = "GET_PASSWORD_USER_DNE";
    ErrorCode[ErrorCode["GET_PASSWORD_NOT_VERIFIED"] = 15] = "GET_PASSWORD_NOT_VERIFIED";
    ErrorCode[ErrorCode["RESET_PASSWORD_FAILED"] = 16] = "RESET_PASSWORD_FAILED";
    ErrorCode[ErrorCode["RESET_PASSWORD_USER_DNE"] = 17] = "RESET_PASSWORD_USER_DNE";
    ErrorCode[ErrorCode["RESET_PASSWORD_NOT_VERIFIED"] = 18] = "RESET_PASSWORD_NOT_VERIFIED";
    ErrorCode[ErrorCode["CREATE_REVIEW_ALREADY_CREATED"] = 19] = "CREATE_REVIEW_ALREADY_CREATED";
    ErrorCode[ErrorCode["VOTE_REVIEW_INVALID_VALUE"] = 20] = "VOTE_REVIEW_INVALID_VALUE";
    ErrorCode[ErrorCode["VOTE_REVIEW_VOTED_ALREADY"] = 21] = "VOTE_REVIEW_VOTED_ALREADY";
    ErrorCode[ErrorCode["VOTE_REVIEW_DNE"] = 22] = "VOTE_REVIEW_DNE";
    ErrorCode[ErrorCode["UPLOAD_TIMETABLE_EXCEED_ENTRY_LIMIT"] = 23] = "UPLOAD_TIMETABLE_EXCEED_ENTRY_LIMIT";
    ErrorCode[ErrorCode["UPLOAD_TIMETABLE_EXCEED_TOTAL_LIMIT"] = 24] = "UPLOAD_TIMETABLE_EXCEED_TOTAL_LIMIT";
    ErrorCode[ErrorCode["GET_REVIEW_INVALID_SORTING"] = 25] = "GET_REVIEW_INVALID_SORTING";
    ErrorCode[ErrorCode["GET_TIMETABLE_INVALID_ID"] = 26] = "GET_TIMETABLE_INVALID_ID";
    ErrorCode[ErrorCode["GET_TIMETABLE_UNAUTHORIZED"] = 27] = "GET_TIMETABLE_UNAUTHORIZED";
    ErrorCode[ErrorCode["GET_TIMETABLE_EXPIRED"] = 28] = "GET_TIMETABLE_EXPIRED";
    ErrorCode[ErrorCode["DEL_TIMETABLE_INVALID_ID"] = 29] = "DEL_TIMETABLE_INVALID_ID";
    ErrorCode[ErrorCode["INPUT_INVALID_LENGTH"] = 30] = "INPUT_INVALID_LENGTH";
    ErrorCode[ErrorCode["INPUT_INVALID_VALUE"] = 31] = "INPUT_INVALID_VALUE";
    ErrorCode[ErrorCode["EXCEED_RATE_LIMIT"] = 32] = "EXCEED_RATE_LIMIT";
})(ErrorCode = exports.ErrorCode || (exports.ErrorCode = {}));
var ReportCategory;
(function (ReportCategory) {
    ReportCategory[ReportCategory["ISSUE"] = 0] = "ISSUE";
    ReportCategory[ReportCategory["FEEDBACK"] = 1] = "FEEDBACK";
    ReportCategory[ReportCategory["COURSE"] = 2] = "COURSE";
    ReportCategory[ReportCategory["REVIEW"] = 3] = "REVIEW";
})(ReportCategory = exports.ReportCategory || (exports.ReportCategory = {}));
var IssueReportType;
(function (IssueReportType) {
    IssueReportType[IssueReportType["OTHER"] = 0] = "OTHER";
    IssueReportType[IssueReportType["UI"] = 1] = "UI";
    IssueReportType[IssueReportType["BUGS"] = 2] = "BUGS";
    IssueReportType[IssueReportType["FEATURES"] = 3] = "FEATURES";
    IssueReportType[IssueReportType["EXPERIENCE"] = 4] = "EXPERIENCE";
    IssueReportType[IssueReportType["COURSE_INFO"] = 5] = "COURSE_INFO";
})(IssueReportType = exports.IssueReportType || (exports.IssueReportType = {}));
var ReviewReportType;
(function (ReviewReportType) {
    ReviewReportType[ReviewReportType["OTHER"] = 0] = "OTHER";
    ReviewReportType[ReviewReportType["HATE_SPEECH"] = 1] = "HATE_SPEECH";
    ReviewReportType[ReviewReportType["PERSONAL_ATTACK"] = 2] = "PERSONAL_ATTACK";
    ReviewReportType[ReviewReportType["SPAM"] = 3] = "SPAM";
    ReviewReportType[ReviewReportType["MISLEADING"] = 4] = "MISLEADING";
})(ReviewReportType = exports.ReviewReportType || (exports.ReviewReportType = {}));
var CourseReportType;
(function (CourseReportType) {
    CourseReportType[CourseReportType["OTHER"] = 0] = "OTHER";
    CourseReportType[CourseReportType["COURSE_TITLE"] = 1] = "COURSE_TITLE";
    CourseReportType[CourseReportType["CREDITS"] = 2] = "CREDITS";
    CourseReportType[CourseReportType["ASSESSMENTS"] = 3] = "ASSESSMENTS";
    CourseReportType[CourseReportType["REQUIREMENTS"] = 4] = "REQUIREMENTS";
    CourseReportType[CourseReportType["DESCRIPTION"] = 5] = "DESCRIPTION";
})(CourseReportType = exports.CourseReportType || (exports.CourseReportType = {}));
var VoteAction;
(function (VoteAction) {
    VoteAction[VoteAction["DOWNVOTE"] = 0] = "DOWNVOTE";
    VoteAction[VoteAction["UPVOTE"] = 1] = "UPVOTE";
})(VoteAction = exports.VoteAction || (exports.VoteAction = {}));
